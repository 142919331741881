import React from 'react';
import PropTypes from 'prop-types';

import '../styles/components/substrate.scss';

const Substrate = ({ innerHTML, background, className }) => {
    const classes = React.useMemo(() => {
        let cls = 'Substrate';
        cls += background ?  ' Substrate-color_' + background : ' Substrate-color_grey'
        cls += className ? ' ' + className : '';

        return cls;
    }, [className, background]);

    return (
        <div className={classes}>
            {innerHTML}
        </div>
    )
};

export const SubstratePropColor = PropTypes.oneOf(['first', 'second', 'grey', 'none']);

Substrate.propTypes = {
    background: SubstratePropColor,
    className: PropTypes.string,
    innerHTML: PropTypes.any
};

export default Substrate;
