import React from 'react';
import PropTypes from 'prop-types';
import ProductCard, {ProductCardPropTypes} from "./ProductCard";
import {arraySplitByChunks} from "../utils/helperFunctions";

const ProductCardsList = ({ cards = [], layout, subColumnFull = false, productCardContentClass }) => {
   const classes = React.useMemo(() => {
       let cls = 'ProductCardsList';
       cls += ` ProductCardsList_layout_${layout}`;
       return cls;
   }, [layout]);

   const columns = React.useMemo(() => {
        return arraySplitByChunks(cards, 2);
   }, [cards]);

   return (
       <div className="clearNegativeMargin">
           <div className={classes}>
               {layout === 'row' && cards.map((card, i) => (
                   <ProductCard key={i} {...card} />
               ))}
               {layout === 'rowString' && cards.map((card, i) => (
                      <ProductCard key={i} {...card} />
               ))}
               {layout === 'column' && columns.map((column, i) => (
                   <div className={`ProductCardsList${subColumnFull === false ? '-Column' : ''}`} key={i}>
                       {column.map((card, j) => (
                           <ProductCard key={i + j} {...card} />
                       ))}
                   </div>
               ))}
            </div>
       </div>
   )
};

export const ProductCardsListPropLayout = PropTypes.oneOf(['column', 'row', 'rowString']);

export const ProductCardsListPropTypes = {
    cards: PropTypes.arrayOf(PropTypes.shape(ProductCardPropTypes)),
    layout: ProductCardsListPropLayout
};

ProductCardsList.propTypes = ProductCardsListPropTypes;

export default ProductCardsList;
