import React from "react";
import PropTypes from "prop-types";
import { Link } from 'gatsby';

import '../styles/components/productCard.scss';
import TextButton from "./TextButton";
import CircleWithImage from "./CircleWithImage";

const ProductCard = ({
                         theme,
                         title,
                         titleStyle="medium",
                         text,
                         icon = '',
                         description = '',
                         cardGoTo,
                         link,
                         children,
                         list,
                         listWithDesc,
                         fullLink,
                         iconColor,
                         titleWithIcon,
                         listStyle="",
                         productCardContentClass="",
                         circleWithImageSize = 'medium'
}) => {
    const classes = React.useMemo(() => {
        let cls = 'ProductCard';
        cls += ' ProductCard_theme_' + theme;

        return cls;
    }, [theme]);

    const colorIcon = React.useMemo(() => {
        if (iconColor === 'second') {
            return 'second';
        }

        if (theme === 'cardFirst' || theme === 'withoutBackground' || theme === 'cardThird') {
            return 'first';
        }
        return 'second';
    }, [theme, iconColor]);

    return (
        <div className={classes}>
            <div className="ProductCard-Shadow" />
            <div className="ProductCard-Inner">
                <div className="wrapperAnimatedElements ProductCard-AnimatedElementsWrapper">
                    <div className="AnimatedElement ProductCard-AnimatedElement ProductCard-AnimatedElement_first" />
                    <div className="AnimatedElement ProductCard-AnimatedElement ProductCard-AnimatedElement_second" />
                </div>
                <div className={`ProductCard-Content ${productCardContentClass}`}>
                    {titleWithIcon ? <div className='ProductCard-Content-TitleWithIcon'>
                        <CircleWithImage color={colorIcon} size={circleWithImageSize} className="ProductCard-Icon">
                            <img src={icon} alt="icon" />
                        </CircleWithImage>
                        <h3 className={`ProductCard-Title ProductCard-Title-${titleStyle}`}>
                            <span dangerouslySetInnerHTML={{__html: title}} /> {description && <span className="ProductCard-Description">{description}</span>}
                        </h3>
                    </div>
                    : <React.Fragment>
                            <CircleWithImage color={colorIcon} size={circleWithImageSize} className="ProductCard-Icon">
                                <img src={icon} alt="icon" />
                            </CircleWithImage>
                            <h3 className={`ProductCard-Title ProductCard-Title-${titleStyle}`}>
                                <span dangerouslySetInnerHTML={{__html: title}} /> {description && <span className="ProductCard-Description">{description}</span>}
                            </h3>
                        </React.Fragment>}
                    {text && (
                        <div className="ProductCard-Text">
                            <div dangerouslySetInnerHTML={{__html: text}} />
                            {children}
                        </div>
                    )}
                    {cardGoTo && (
                        <div className="Block-Links-InnerBlock">
                            <TextButton to={cardGoTo.to} href={cardGoTo.href} iconId="ArrowRight" styleTheme="goTo">{cardGoTo.text}</TextButton>
                        </div>
                    )}
                    {list && (
                        <div className="ProductCard-List">
                            <ul className={`cardList${theme === 'cardSecond' || theme === 'cardFourth' ? ' cardList_theme_second' : ''}`}>
                                {list.map((listItem, i) => (
                                    <li className={listStyle} key={i}>{listItem}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {listWithDesc && (
                        <div className="ProductCard-List">
                            <ul className="cardList ProductCard-List-WithDesc">
                                {listWithDesc.map((listItem, i) => (
                                    <li key={i}>
                                        <div className="ProductCard-List-WithDesc-Title">{listItem.title}</div>
                                        <div className="ProductCard-List-WithDesc-Desc">{listItem.text}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {link && (
                        <div className="ProductCard-Link">
                            <div className="textButtonBlueWrapper">
                                <TextButton to={link.to} href={link.href} iconId="ArrowRight" styleTheme="goTo">{link.text}</TextButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {fullLink && fullLink.to && (
                <Link to={fullLink.to} className="ProductCard-FullLink">&#8203;</Link>
            )}
            {fullLink && fullLink.href && (
                <a href={fullLink.href} target="_blank" rel="noreferrer" className="ProductCard-FullLink">&#8203;</a>
            )}
        </div>
    )
};

export const ProductCardPropTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    titleWithIcon: PropTypes.bool,
    description: PropTypes.string,
    icon: PropTypes.any.isRequired,
    theme: PropTypes.oneOf(['cardFirst', 'cardSecond', 'withoutBackground', 'cardThird', 'cardFourth']).isRequired,
    list: PropTypes.arrayOf(PropTypes.string),
    circleWithImageSize: PropTypes.string,
    fullLink: PropTypes.shape({
        to: PropTypes.string,
        href: PropTypes.string
    }),
    link: PropTypes.shape({
        to: PropTypes.string,
        href: PropTypes.string,
        text: PropTypes.string
    })
};

ProductCard.propTypes = ProductCardPropTypes;

export default ProductCard;
