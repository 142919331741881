import React from "react";
import SEO from "../components/seo";

import '../styles/pages/selfEmployed.scss';

import Block from "../blocks/Block";
import BlockFogging from "../blocks/BlockFogging";
import BlockSection from "../blocks/BlockSection";
import Layout from "../layout/Layout";
import Title from "../components/typography/Title";
import ProductCardsList from "../components/ProductCardsList";
import SimpleCard from "../components/SimpleCard";
import Substrate from "../components/Substrate";
import StepsList from "../components/StepsList";
import OpeningRectangle from "../components/OpeningRectangle";
import LogoWithBubble from "../components/LogoWithBubble";
import {gridBreakpoints} from "../grid/grid";
import { navigate } from "gatsby"

import exampleSolution from '../images/examples/Self-Employed-Dashboard-Day 2.svg'

import enMessages from '../i18n/selfEmployed/en.js'
import ruMessages from '../i18n/selfEmployed/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const SelfEmployedPage = ({ pathContext: { locale } }) => {
    const [mdDown, setMdDown] = React.useState(false);

    React.useEffect(() => {
        setMdDown(window.innerWidth < gridBreakpoints.md);
        window.addEventListener("resize", () => {
            setMdDown(window.innerWidth < gridBreakpoints.md);
        });
        return () => {
            window.removeEventListener("resize", () => {});
        };
    }, []);

    if (locale === 'en' && typeof window !== 'undefined') {
        navigate('/404')
        return null
    }

    return (
        <div className='SelfEmployed'>
            <Layout locale={locale}>
                <div id='SelfEmployed'>
                    <SEO
                        title={messages[locale].seo.title}
                        description={messages[locale].seo.description}
                    />
                    <div id='SelfEmployed-OurSolution'>
                        <Block config={{ animation: '5' }} classNameInner="margin-none">
                                <div className="container">
                                    <BlockSection className='margin-top_none Flex-column-center'>
                                        <Title
                                            color='first'
                                            level="h1"
                                            className="Block-Title text-center"
                                            headerStyleNumb={'8'}
                                            innerHTML={messages[locale].mainTitle}
                                        />
                                        <div className='Block-Text text7'>
                                            {messages[locale].mainDescription}
                                        </div>
                                        <BlockFogging img={exampleSolution} title={messages[locale].whoIsTheSolutionFor.title}/>
                                        <div id='SelfEmployed-InfoBlock'>
                                            <div>
                                                <BlockSection className='margin-none'>
                                                    <ProductCardsList
                                                        cards={messages[locale].whoIsTheSolutionFor.cards}
                                                        layout='row'
                                                    />
                                                </BlockSection>
                                            </div>
                                        </div>
                                    </BlockSection>
                                </div>
                        </Block>
                    </div>

                    <Block config={{ baseBorder: true }}>
                        <div id='SelfEmployed-Payments'>
                            <div className="container">
                                <BlockSection className='Flex-column-center'>
                                    <Title
                                        color='second'
                                        level="h2"
                                        className="Block-Title text-center"
                                        headerStyleNumb={'9'}
                                        innerHTML={messages[locale].payout.title}
                                    />
                                    <div className='Block-Text text7'>
                                        {messages[locale].payout.description}
                                    </div>

                                    <div id='SelfEmployed-PayoutCards'>
                                        {messages[locale].payout.listCard.map((item, index) => {
                                            return <SimpleCard key={index} {...item} />
                                        })}
                                    </div>

                                    <div id='SelfEmployed-Partners'>
                                        <BlockSection className='Flex-column-center margin-none'>
                                            <Title
                                                color='grey'
                                                level="h2"
                                                className="Block-Title text-center text-transform_normal font-weight_bold SelfEmployed_title"
                                                headerStyleNumb={'9'}
                                                innerHTML={messages[locale].payout.partners.title}
                                            />
                                            {messages[locale].payout.partners.list.map(item => {
                                                return <Substrate key={item.img} innerHTML={<img src={item.img} alt=""/>}/>
                                            })}
                                        </BlockSection>
                                    </div>
                                </BlockSection>
                            </div>
                        </div>
                    </Block>

                    <Block config={{ animation: '2' }}>
                        <div id='SelfEmployed-ThatsWhyItSCool'>
                            <div className="container">
                                <BlockSection>
                                    <Title
                                        color='first'
                                        level="h2"
                                        className="Block-Title text-center SelfEmployed_title"
                                        headerStyleNumb={'9'}
                                        innerHTML={messages[locale].thatsWhyItSCool.title}
                                    />
                                    <div id='SelfEmployed-ThatsWhyItSCool_smallCards'>
                                        <ProductCardsList cards={messages[locale].thatsWhyItSCool.cards} layout='row' />
                                    </div>
                                </BlockSection>

                                <Block classNameInner="margin-none">
                                    <div id="SelfEmployed-SolutionForWork">
                                        <BlockSection className="margin-top_none Flex-column-center">
                                            <Title
                                                color='second'
                                                level="h2"
                                                className="Block-Title text-center"
                                                headerStyleNumb={'10'}
                                                innerHTML={messages[locale].solutionForWork.title}
                                            />
                                            <div className='Block-Text text7'>
                                                {messages[locale].solutionForWork.description}
                                            </div>
                                            <ProductCardsList cards={messages[locale].solutionForWork.cards} layout='row' />
                                        </BlockSection>
                                    </div>
                                </Block>
                            </div>
                        </div>
                    </Block>

                    <Block classNameInner="margin-none_bottom" config={{ baseBorder: true }}>
                        <div id='SelfEmployed-StepsList' className="container">
                            <BlockSection className="margin-none">
                                <Title
                                    level="h2"
                                    className="Block-Title text-center SelfEmployed_title"
                                    headerStyleNumb={'10'}
                                    innerHTML={messages[locale].howItWorks.title}
                                />
                                <StepsList color='second' elements={messages[locale].howItWorks.list} viewType="ol" />
                            </BlockSection>
                        </div>
                    </Block>

                    <Block config={{ animation: '2' }}>
                        <BlockSection className="margin-none">
                            <Title
                                level="h2"
                                className="Block-Title text-center SelfEmployed_title"
                                headerStyleNumb={'10'}
                                innerHTML={messages[locale].questionAnswer.title}
                            />

                            <div id='SelfEmployed-Rectangle' className={`Block-Text text4 ${!mdDown && 'container'}`}>
                                {(messages[locale].questionAnswer.list || []).map((item, index) => {
                                    return <OpeningRectangle
                                        key={index}
                                        rectangleConfig={{
                                            borderBottom: 'none'
                                        }}
                                        listRectangles={item.rectangle}
                                        title={item.title}
                                        styleText={'small'}
                                    />
                                })}
                            </div>

                            <div className="container Block-Text text8" dangerouslySetInnerHTML={{ __html: messages[locale].questionAnswer.moreInfo }}/>
                        </BlockSection>
                    </Block>

                    <Block config={{ baseBorder: true }}>
                        <div className="container">
                            <BlockSection className="margin-none">
                                <div id='SelfEmployed-AboutBank'>
                                    <LogoWithBubble/>
                                    <div id='SelfEmployed-AboutBank_description'>
                                        <Title
                                            color='first'
                                            level="h2"
                                            className={`Block-Title ${mdDown && 'text-center'}`}
                                            headerStyleNumb={'10'}
                                            innerHTML={messages[locale].aboutBank.title}
                                        />
                                        <Title
                                            level="h4"
                                            className={`Block-Title ${mdDown && 'text-center'}`}
                                            headerStyleNumb={'11'}
                                            innerHTML={messages[locale].aboutBank.subTitle}
                                        />
                                        <div className={`Block-Text text8 ${mdDown && 'text-center'}`} dangerouslySetInnerHTML={{ __html: messages[locale].aboutBank.description }}/>
                                    </div>
                                </div>
                            </BlockSection>
                        </div>
                    </Block>
                </div>
            </Layout>
        </div>
    )
};

export default SelfEmployedPage;
