import React from "react";
import PropTypes from "prop-types";

import '../styles/components/simpleCard.scss';

const SimpleCard = ({
                         theme,
                         title,
                         icon = '',
                         description = '',
                         productCardContentClass
                     }) => {
    const classes = React.useMemo(() => {
        let cls = 'SimpleCard';
        cls += ' SimpleCard_theme_' + theme;

        return cls;
    }, [theme]);

    return (
        <div className={classes}>
            <div className="SimpleCard-Inner">
                <div className={`SimpleCard-Content ${productCardContentClass}`}>
                    <img className='SimpleCard-Icon' src={icon} alt="icon" />
                    <h3 className="SimpleCard-Title">
                        <span dangerouslySetInnerHTML={{__html: title}} /> {description && <span className="SimpleCard-Description">{description}</span>}
                    </h3>
                </div>
            </div>
        </div>
    )
};

export const SimpleCardPropTypes = {
    theme: PropTypes.string,
    description: PropTypes.string,
    productCardContentClass: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.any.isRequired,
};

SimpleCard.propTypes = SimpleCardPropTypes;

export default SimpleCard;
