import smile from "../../images/icons/styled/first/Smile.svg";
import card from "../../images/icons/styled/first/Card.svg";
import money from "../../images/icons/styled/second/Money.svg";
import iconHome from "../../images/icons/styled/first/Home.svg";
import iconNotebook from "../../images/icons/styled/second/Notebook.svg";
import iconDesign from "../../images/icons/styled/first/Design.svg";
import iconApi from "../../images/icons/styled/second/API.svg";
import iconCopyright from "../../images/icons/styled/first/Copyright.svg";
import iconTranslate from "../../images/icons/styled/second/Translate.svg";
import imgSkyEng from "../../images/partners/Skyeng.svg";
import iconShield from "../../images/icons/styled/first/Shield.svg";
import iconFlag from "../../images/icons/styled/first/Flag.svg";
import iconMonitor from "../../images/icons/styled/first/Monitor.svg";
import iconReceipt from "../../images/icons/styled/first/Receipt.svg";
import iconUserCheck from "../../images/icons/styled/second/UserCheck.svg";
import iconStarCheck from "../../images/icons/styled/second/StarCheck.svg";

export default {
    seo: {
      title: 'Выплаты самозанятым и физлицам на счета, банковские карты и электронные кошельки | Банк 131',
      description: 'Массовые выплаты физлицами и самозанятым. Решение для платформ онлайн обучения, платформ для фрилансеров. Для международных компаний – выплаты исполнителям в России. Прямая интеграция с ФНС, быстрые выплаты по реквизитам банковской карты, счета или кошелька.'
    },
    mainTitle: 'Наше решение <br/>для самозанятых',
    mainDescription: 'Станьте самозанятым вместе с Банком 131. Регистрируйтесь на сайтах\n' +
        '                            партнеров банка, оказывайте услуги и получайте вознаграждение.\n' +
        '                            Банк обеспечивает безопасные расчеты интернет-сервисов\n' +
        '                            с самозанятыми и автоматически регистрирует их в ФНС.',

    whoIsTheSolutionFor: {
        title: 'Кому подходит решение',
        cards: [
            {
                "text": '<div class="ProductCard-Text-center ProductCard-Text-width_small">Вы уже являетесь самозанятым \n' +
                    'или планируете им стать</div>',
                "icon": smile,
                "description": '',
                "productCardContentClass": 'Flex-column-center',
                "theme": 'withoutBackground'
            },
            {
                "text": '<div class="ProductCard-Text-center ProductCard-Text-width_small">Вы планируете получать \n' +
                    'выплаты за услуги от одного \n' +
                    'из партнеров банка</div>',
                "icon": money,
                "description": '',
                "productCardContentClass": 'Flex-column-center',
                "theme": 'withoutBackground',
                "iconColor": 'second'
            },
            {
                "text": '<div class="ProductCard-Text-center ProductCard-Text-width_small">Вы хотите, чтобы регистрация выплаты и передача чека вам происходили автоматически</div>',
                "icon": card,
                "description": '',
                "productCardContentClass": 'Flex-column-center',
                "theme": 'withoutBackground'
            },
        ]
    },
    payout: {
        title: 'Выплаты самозанятым',
        description: 'Мы разработали одно из первых решений \n' +
            'для самозанятых граждан, получающим выплаты \n' +
            'от интернет-бизнеса. Вам подойдет это решение, \n' +
            'если вы оказываете услуги одному из наших партнеров.',
        listCard: [
            {
                "title": 'Внештатные исполнители',
                "icon": iconHome,
            },
            {
                "title": 'Фрилансеры',
                "icon": iconNotebook,
            },
            {
                "title": 'Дизайнеры',
                "icon": iconDesign,
            },
            {
                "title": 'Вебмастера',
                "icon": iconApi,
            },
            {
                "title": 'Копирайтеры',
                "icon": iconCopyright,
            },
            {
                "title": 'Переводчики',
                "icon": iconTranslate,
            },
        ],
        partners: {
            title: 'Наши партнеры',
            list: [
                {
                    img: imgSkyEng
                }
            ]
        }
    },
    thatsWhyItSCool: {
        title: 'Вот почему это круто',
        cards: [
            {
                title: 'Надежно',
                "text": 'Партнеры банка, российские \n' +
                    'и зарубежные компании, привлекают самозанятых \n' +
                    'к оказанию услуг в соответствии \n' +
                    'с законодательством РФ',
                "icon": iconShield,
                "description": '',
                "theme": 'cardFirst',
                "titleWithIcon": true,
                circleWithImageSize: 'small'
            },
            {
                title: 'Прозрачно',
                "text": 'Банк автоматически регистрирует выплаты, полученные от партнеров банка, в ФНС',
                "icon": iconFlag,
                "description": '',
                "theme": 'cardFirst',
                "titleWithIcon": true,
                circleWithImageSize: 'small'
            },
            {
                title: 'Удобно',
                "text": 'Современный Личный кабинет Банка 131 позволяет самозанятому управлять своими доходами \n' +
                    'и налогами',
                "icon": iconMonitor,
                "description": '',
                "theme": 'cardFirst',
                "titleWithIcon": true,
                circleWithImageSize: 'small'
            },
            {
                title: 'Комфортно',
                "text": 'Банк передаст чек об оплате плательщику сразу после расчетов',
                "icon": iconReceipt,
                "description": '',
                "theme": 'cardFirst',
                "titleWithIcon": true,
                circleWithImageSize: 'small'
            },
        ],
    },
    solutionForWork: {
        title: 'Решение для работы с самозанятыми',
        description: 'Банк 131 предоставляет платежные сервисы российским и зарубежным компаниям и обеспечивает их расчеты \n' +
            'с самозанятыми. Для осуществления расчетов в соответствии с российским законодательством, Банк 131 предлагает самозанятым регистрацию в Личном кабинете. Она позволит автоматически регистрировать выплаты от партнерских сервисов в ФНС.',
        cards: [
            {
                title: 'Регистрация самозанятых',
                "text": 'Зарегистрироваться в Личном кабинете Банка 131 можно онлайн. Для этого потребуется привязать личный кабинет в ФНС к Личному кабинету в Банке 131. Если вы еще не используете специальный налоговый режим, Банк поставит вас на учет автоматически. \n' +
                    'Для регистрации понадобится паспорт и ИНН.\n',
                "icon": iconUserCheck,
                "description": '',
                "theme": 'cardSecond',
                "titleWithIcon": true,
                circleWithImageSize: 'small'
            },
            {
                title: 'Автоматическая \n' +
                    'регистрация дохода',
                "text": 'Банк зарегистрирует выплату от партнерского сервиса \n' +
                    'и передаст ему чек — самозанятому не нужно делать это \n' +
                    'вручную. Автоматически регистрируется только доход, полученный от партнерского сервиса Банка 131. Выплаты от других людей и компаний можно регистрировать вручную \n' +
                    'с помощью Личного кабинета.',
                "icon": iconStarCheck,
                "description": '',
                "theme": 'cardSecond',
                "titleWithIcon": true,
                circleWithImageSize: 'small'
            },
        ]
    },
    howItWorks: {
        title: 'Как это работает',
        "list": [
            {
                "title": 'Начните оказывать услуги',
                "text": 'Зарегистрируйтесь в сервисе, \n' +
                    'являющимся партнером Банка 131.',
                circleWithImageSize: 'small'
            },
            {
                "title": 'Станьте самозанятым',
                "text": 'Предоставьте права Банку 131 на действия от вашего имени. Подробная инструкция размещена на платформе партнерского сервиса.',
                circleWithImageSize: 'small'
            },
            {
                "title": 'Получайте деньги',
                "text": 'Банк 131 осуществит выплату \n' +
                    'от заказчика на вашу банковскую \n' +
                    'карту или счет.',
                circleWithImageSize: 'small'
            },
            {
                "title": 'Формируйте чеки',
                "text": 'Банк 131 сразу же уведомит ФНС \n' +
                    'об оплате и передаст чек заказчику.',
                circleWithImageSize: 'small'
            }
        ]
    },
    questionAnswer: {
        title: 'Вопрос-ответ',
        list: [
            {
                title: 'Как расчитать сумму налога?',
                rectangle: [
                    {
                        description: 'Самостоятельно ничего считать не нужно. Применение налогового вычета, учет ' +
                            'налоговых ставок в зависимости от налогоплательщика, контроль над ограничением по сумме ' +
                            'дохода и другие особенности расчета полностью автоматизированы.'
                    }
                ]
            },
            {
                title: 'Какой срок оплаты налога \n' +
                    'на профессиональный доход?',
                rectangle: [
                    {
                        description: 'Оплата налога на профессиональный доход производится ежемесячно не позднее 25 ' +
                            'числа месяца, следующего за истекшим налоговым периодом (месяцем). Если эта дата является ' +
                            'праздничным или выходным днем, тогда срок оплаты переносится на первый рабочий день.'
                    }
                ]
            },
            {
                title: 'Кто вправе стать «Самозанятым» \n' +
                    'и применять налог на профессиональный налог?',
                rectangle: [
                    {
                        description: 'Применять специальный налоговый режим «Налог на профессиональный доход» ' +
                            'могут физические лица (граждане России и других государств ЕАЭС), а также ' +
                            'индивидуальные предприниматели, не имеющие наемных работников по трудовому договору. ' +
                            'Они могут осуществлять реализацию собственных товаров (работ, услуг, имущественных прав), ' +
                            'местом ведения деятельности которых является территория любого из субъектов Российской Федерации, включенных в эксперимент.'
                    }
                ]
            },
            {
                title: 'Кто не вправе применять специальный налоговый режим «налог на профессиональный доход»?',
                rectangle: [
                    {
                        description: 'Не вправе применять специальный налоговый режим:<br/>' +
                            'лица, осуществляющие реализацию подакцизных товаров и товаров, подлежащих обязательной маркировке средствами идентификации в соответствии с законодательством Российской Федерации;<br/>' +
                            'лица, осуществляющие перепродажу товаров, имущественных прав, за исключением продажи имущества, использовавшегося ими для личных, домашних и (или) иных подобных нужд;<br/>' +
                            'лица, занимающиеся добычей и (или) реализацией полезных ископаемых;<br/>' +
                            'лица, имеющие работников, с которыми они состоят в трудовых отношениях;<br/>' +
                            'лица, ведущие предпринимательскую деятельность в интересах другого лица на основе договоров поручения, договоров комиссии либо агентских договоров, за исключением случаев ведения такой деятельности при условии применения налогоплательщиком-продавцом контрольно-кассовой техники при расчетах с заказчиками за указанные услуги в соответствии с действующим законодательством РФ о применении контрольно-кассовой техники;<br/>' +
                            'лица, оказывающие услуги по доставке товаров с приемом (передачей) платежей за указанные товары в интересах других лиц, за исключением оказания таких услуг при условии применения налогоплательщиком зарегистрированной продавцом товаров контрольно-кассовой техники при расчетах с покупателями (заказчиками) за указанные товары в соответствии с действующим законодательством о применении контрольно-кассовой техники;<br/>' +
                            'лица, применяющие иные специальные налоговые режимы или ведущие предпринимательскую деятельность, доходы от которой облагаются налогом на доходы физических лиц, за исключением случаев, предусмотренных частью 4 статьи 15 Федерального закона от 27.11.2018 №422-ФЗ;<br/>' +
                            'налогоплательщики, у которых доходы, учитываемые при определении налоговой базы, превысили в текущем календарном году 2,4 миллиона рублей.'
                    }
                ]
            },
            {
                title: 'Если у меня есть задолженность как у физического лица, позволяет ли это \n' +
                    'на регистрацию в качестве плательщика налога на профессиональный доход',
                rectangle: [
                    {
                        description: 'Подробней о режиме налога на профессиональный доход вы можете узнать на сайте <a href="https://npd.nalog.ru" target="_blank">npd.nalog.ru</a>.'
                    }
                ]
            },
        ],
        moreInfo: 'Подробней о режиме налога на профессиональный доход вы можете узнать на сайте <a href="https://npd.nalog.ru" target="_blank">npd.nalog.ru</a>.'
    },
    aboutBank: {
        title: 'О банке',
        subTitle: 'Банк 131 — Банк цифровой экономики',
        description: 'Банк 131 — первая частная компания \n' +
            'за последние несколько лет, которая получила лицензию Банка России. Мы предлагаем только технологичные решения для российскихи международных интернет- бизнесов и цифровой экономики. Наша команда имеет большой опыт в построении масштабных цифровых проектов \n' +
            'в России и за рубежом.<br/>' +
            '<br/>' +
            'Надежность сервиса гарантируется низкорисковой транзакционной бизнес-моделью финтеха. Мы не выдаем кредиты, а собственный капитал и средства клиентов размещаем на депозите в ЦБ.'
    }
}
