import React from 'react';
import PropTypes from 'prop-types';

const BlockFogging = ({ img, className, title }) => {
    const classes = React.useMemo(() => {
        let cls = 'Block-Fogging';
        cls += className ? ` ${className}` : '';

        return cls;
    }, [className]);

    return (
        <React.Fragment>
            <div className={classes}>
                <img className='Block-Fogging-Img' src={img} alt=""/>
            </div>
            <div className='Fogging'>
                <div className='Block-Fogging-Title'>
                    {title}
                </div>
            </div>
        </React.Fragment>
    )
};

BlockFogging.propTypes = {
    className: PropTypes.string,
};

export default BlockFogging;
