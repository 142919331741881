import React from 'react';
import '../styles/components/logoWithBubble.scss';
import logo from '../images/logo/red/Brand-Mark-Bank-131-Red.svg'
import bubbleRed from '../images/icons/bubble-svg/Bubble-Red-2.svg'
import bubbleBlue from '../images/icons/bubble-svg/Bubble-Blue-2.svg'
import bubbleYellow from '../images/icons/bubble-svg/Bubble-Yellow-2.svg'
import bubbleGreen from '../images/icons/bubble-svg/Bubble-Green-2.svg'

const LogoWithBubble = () => {
    return (
        <div className='LogoWithBubble'>
            <div className='LogoWithBubble-Inner'>
                <div className='LogoWithBubble-WrapperBubble'>
                    <img className='LogoWithBubble-Bubble LogoWithBubble-Bubble_size_1 pulse' src={bubbleRed} alt=""/>
                    <img className='LogoWithBubble-Bubble LogoWithBubble-Bubble_size_2 pulse' src={bubbleBlue} alt=""/>
                    <img className='LogoWithBubble-Bubble LogoWithBubble-Bubble_size_3 pulse' src={bubbleGreen} alt=""/>
                    <img className='LogoWithBubble-Bubble LogoWithBubble-Bubble_size_4 pulse' src={bubbleYellow} alt=""/>
                </div>
                <img className='LogoWithBubble-Logo' src={logo} alt=""/>
            </div>
        </div>
    )
};

export default LogoWithBubble;
